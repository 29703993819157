/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-lines */
import { paramCase } from "change-case";
import * as React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { classNames } from "@plinknz/tah-website-elements";
import { BlockQuote } from "../components/blockquote";
import { Button } from "../components/button";
import { Hero } from "../components/hero";
import { Loader } from "../components/loader";
import { CallToActionBlock } from "../components/content-blocks/call-to-action-block";
import { DownloadBlock } from "../components/content-blocks/download-block";
import { GalleryBlock } from "../components/content-blocks/gallery";
import { GoogleCalendar } from "../components/content-blocks/google-calendar";
import { LinksBlock } from "../components/content-blocks/links-block";
import { NewsGridBlock } from "../components/content-blocks/news-grid-block";
import { YoutubePlayerContentBlock } from "../components/content-blocks/youtube-player";
import { AddressFinder } from "../components/form/address-finder";
import { Checkbox } from "../components/form/checkbox";
import { DatePicker } from "../components/form/datepicker";
import { Form } from "../components/form/form";
import { SignatureCanvas } from "../components/form/signature-canvas";
import { Radio, RadioColumn } from "../components/form/radio";
import { Dropdown } from "../components/form/dropdown";
import { Whakapapa } from "../components/form/whakapapa/whakapapa";
import { Field } from "../components/form/field";
import { Waiata } from "../components/content-blocks/waiata";

interface StyleGuideSectionProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string;
    subtitle?: string;
    contain?: boolean;
}

const StyleGuideSection = ({
    title,
    subtitle,
    children,
    contain = false,
    className,
    ...props
}: StyleGuideSectionProps) => (
    <section
        id={paramCase(title)}
        className={classNames(
            "style-guide-section",
            { "constrain-width": contain },
            className
        )}
        {...props}>
        <div
            className={`style-guide-section-heading ${
                !contain ? "constrain-width" : ""
            }`}>
            <h1 className="heading-2 primary-color rotate">
                {title}{" "}
                {subtitle && (
                    <small className="style-guide-section-subtitle">
                        | {subtitle}
                    </small>
                )}
            </h1>
            <hr className="style-guide-section-break" />
        </div>
        <div className="style-guide-section-content">{children}</div>
    </section>
);

interface SwatchProps {
    title: string;
    value: string;
    outline?: boolean;
}
const Swatch = ({ title, value, outline = false }: SwatchProps) => (
    <div className={`swatch small ${outline ? "outline" : ""} colour-${value}`}>
        <div className="swatch-inner">
            <p className="swatch-title">{title}</p>
            <div role="group" className="swatch-variations">
                <span className="swatch-variation tint-1" />
                <span className="swatch-variation shade-1" />
            </div>
        </div>
    </div>
);

export const StyleGuide = () => {
    return (
        <div className="style-guide">
            <Helmet>
                <title>Style Guide - Ngāti Kuia</title>
            </Helmet>

            <Hero
                title="Ārahi Huatau / Style Guide"
                subtitle="A working site web design style guide."
            />

            <StyleGuideSection title="Tikiake" subtitle="One no image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ngāti Kuia Annual Reports",
                        description:
                            "Ngāti Kuia annual report for the financial year 2020-2021",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="One with image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Annual Report 2020",
                        description: "",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                image: { url: "/images/kānuka-tea.jpg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="One with image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Annual Report 2020",
                        description: "Rēhita mai",
                        files: [
                            {
                                id: "1",
                                name: "Register with Ngāti Kuia",
                                image: { url: "/images/waka-side-view.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Tikiake"
                subtitle="One with image and description">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ammendments to Ngāti Kuia Trust Deed",
                        description:
                            "Ngāti Kuia annual report for the financial year 2020-2021",
                        files: [
                            {
                                id: "1",
                                name: "Ammendments to Ngāti Kuia Trust Deed",
                                image: { url: "/images/art-craft.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="One with image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ammendments to Ngāti Kuia Trust Deed",
                        description: "",
                        files: [
                            {
                                id: "1",
                                name:
                                    "ANgāti Kuia Trust Newsletter - Kohitea 2019 | January 2019",
                                image: { url: "/images/paddles.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 2680900.21,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="2 Tikiake"
                subtitle="Two, one has an Image, one using default image">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ngāti Kuia Annual Reports",
                        description:
                            "Ngāti Kuia annual report for the financial year 2021- 2022",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                image: { url: "/images/te-hora-flag.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "https://mailchi.mp/b6fa5a334141/te-pakohe-tokerau-2022?e=14b8af76b1",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                name: "Ngāti Kuia Annual Reports",
                                file: {
                                    id: "1",
                                    name: "Chair Report 2019 - 2020",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 13809.66,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="3 Tikiake" subtitle="Three no images">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ngāti Kuia Annual Reports",
                        description:
                            "Ngāti Kuia annual report for the financial year 2021- 2022",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                file: {
                                    id: "1",
                                    name: "Chair Report  2019 - 2020",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 13809.66,
                                },
                            },
                            {
                                id: "3",
                                file: {
                                    id: "1",
                                    name: "Chair Report 2019 - 2020",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 13809.66,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="3 Tikiake" subtitle="Three with images">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ngāti Kuia Annual Reports",
                        description:
                            "Ngāti Kuia annual report for the financial year 2021- 2022",
                        files: [
                            {
                                id: "1",
                                name: "Annual Report 2020",
                                image: { url: "/images/te-hora-flag.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Te Pakohe tokerau 2022",
                                    url:
                                        "https://mailchi.mp/b6fa5a334141/te-pakohe-tokerau-2022?e=14b8af76b1",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                name: "Annual Report 2019",
                                image: { url: "/images/paddles.jpeg" },
                                file: {
                                    id: "1",
                                    name: "Election Notice 2021",
                                    url:
                                        "https://images.test.ngatikuia.iwi.nz/Maranga_Mai_May_2016_c2cb1285e5.pdf",
                                    size: 13809.66,
                                },
                            },
                            {
                                id: "3",
                                name: "Annual Report 2018",
                                image: { url: "/images/kānuka-tea.jpg" },
                                file: {
                                    id: "1",
                                    name: "Chair Report 2020",
                                    url: "http://eepurl.com/hI9AK1",
                                    size: 13809.66,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Kawepūrongo"
                subtitle="News Grid"
                contain={false}>
                <NewsGridBlock
                    data={{
                        __typename: "ComponentContentBlocksNewsGrid",
                        id: "1",
                        title: "Stay up to date",
                        pages: [
                            {
                                title: "Roadshows",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/roadshow.jpg",
                                },
                            },
                            {
                                title: "Kaumātua Christmas Lunch",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/xmas-lunch.jpg",
                                },
                            },
                            {
                                title: "Wānanga Pakohe",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/wānanga.jpg",
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Whakahauhau" subtitle="Call To Action">
                <CallToActionBlock
                    data={{
                        __typename: "ComponentContentBlocksCallToAction",
                        id: "1",
                        callToActionTitle: "Register with Ngāti Kuia",
                        description: "Naumai haere mai",
                        action: {
                            label: "Join Us",
                            link: { slug: "/link" },
                            member_link: { slug: "/link" },
                        },
                        background: { url: "/images/intro-bg.jpg" },
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Wātaka Kūkara"
                subtitle="Google Calendar"
                contain>
                <GoogleCalendar
                    data={{
                        __typename: "ComponentContentBlocksGoogleCalendar",
                        id: "1",
                        calendarId: "b4cfchp0jp9st71vhqm1hjgiio",
                        active: "true",
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Waiata" subtitle="Song" contain>
                <Waiata
                    data={{
                        __typename: "ComponentContentBlocksWaiata",
                        title: "E Koro Paroa",
                        audio_file: {
                            id: "one",
                            name: "E Koro Paroa",
                            url:
                                "https://images.test.ngatikuia.iwi.nz/E_Koro_Paroa_d55601da45.mp3",
                            size: "this size",
                            ext: "ext",
                        },
                        timed_kupu:
                            "0:E:1:Koro:2:Pāroa::6.75:Tirohia:9:iho:10:au::14:Ka:15.5:waiho:16.25:ngā:17:pūharakeke:22.5:e::27:I:28:tuia:29:i:29.5:te:30:hoki:31:nei::32:Mō:33:Kahuroa:38:e:38.5:tū:39:nei::44:He:45:wehi:46:nōku::48:Tūtūmāpou:54:e:54.5:tū:55:mai:56:rā:58:a::63:Kia:64.25:whaikōrero:68:mai::69:I:69.25:te:70:tākiritanga::73.25:I:73.5:te:74.25:ata::75:I:76.5:whanatu:77.5:ai:78.5:koutou:80:e",
                    }}
                />
                <Waiata
                    data={{
                        __typename: "ComponentContentBlocksWaiata",
                        title: "E Te Whakatau",
                        audio_file: {
                            id: "two",
                            name: "E Te Whakatau",
                            url:
                                "https://images.test.ngatikuia.iwi.nz/E_te_Whakatau_d6230ef469.mp3",
                            size: "this size",
                            ext: "ext",
                        },
                        timed_kupu:
                            "0:E:.75:te:1.75:whakatau:3.5:nāu:4:te:4.5:pōhiri::7.75:Ki:8:Te:8.5:Hoiere:10:o:10.25:Matua:11.5:Hautere::14:Kaikaiāwaro:17.5:nāu:18:i:18.5:arataki:20:–:21:ki:22.5:uta!::28:Tūtūmāpou:31:e:31.5:tū:32:mai:32.25:rā:32.5:ki:33:runga::36:Koia:36.5:te:37:iwi:38:Pakohe:39:Ngāti:40:Kuia::42.5:Maranga:44:mai:44.5:rā:45.5:e:46.5:ngā:47.5:mokopuna:49.25:ki:50:runga::56:Ki:57:te:58:poho:59:o:59.5:Kuia::60.5:Kei:61:raro:62:Motuweka::64:Titirangi:66:moutere:67.5:titi:68.25:e::71:Kaituna,:72:Ruapaka,:74:Te:74:Matau,:75.75:Te:75.75:Tauranga::78:Maungatapu:79:ki:79.75:Titiraukawa:82:e::87:E:88:te:88.75:Whakatau:90:-:91:nāu:91.5:te:92:pōhiri",
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tae" subtitle="Colours" contain>
                <div className="style-guide-swatches" role="group">
                    <Swatch title="Primary" value="primary" />
                    <Swatch title="Secondary" value="secondary" outline />
                    <Swatch title="Body" value="body" />
                    <Swatch title="Dark" value="dark" />
                    <Swatch title="Light" value="light" outline />
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Momotuhi" subtitle="Type" contain>
                <h1>Header 1</h1>
                <h2 className="heading-2">Header 2</h2>
                <h3>Header 3</h3>
                <h4>Header 4</h4>
                <h5>Header 5</h5>
                <h6>Header 6</h6>

                <p>
                    Matua Hautere te tipuna i hoea mai rā. I Hawaiki-nui, i
                    Hawaiki-roa, Hawaiki-pāmamao e. Te ara whakaheke i tōna
                    tipuna a Kupe. He tipua, he tangata Matua Hautere e.
                </p>
                <p>
                    E te whakatau nāu te pōhiri Ki Te Hoiere o Matua Hautere
                    Kaikaiāwaro nāu i arataki - ki uta! Tūtūmāpou e tū mai rā ki
                    runga Koia te iwi Pakohe Ngāti Kuia E te whakatau nāu te
                    pōhiri Ki Te Hoiere o Matua Hautere Kaikaiāwaro nāu i
                    arataki - ki uta! Tūtūmāpou e tū mai rā ki runga Koia te iwi
                    Pakohe Ngāti Kuia
                </p>
                <p>
                    Ekore <em>e tika</em> kia noho he Maori rawakore ki tenei
                    whenua; ehara tenei i te mea e ora ai tatou e tika ai ranei
                    ratou. E mea ana tatou kia noho te Maori i runga i te
                    rangimarie penei me <strong>tatou, a e ki ana</strong> ahau
                    mehemea tera tetahi iwi i whiwhi nui ki
                </p>
            </StyleGuideSection>

            <StyleGuideSection title="Rārangi" subtitle="Lists" contain>
                <h4>Unordered</h4>
                <ul>
                    <div>
                        <li>
                            <div className="style-guide-section-list">
                                Ki te whenua{" "}
                                <strong>
                                    kua kite ratou kei to mawehe atu{" "}
                                </strong>{" "}
                                o ratou whenua i a ratou, e tika ana ano kia.
                            </div>
                        </li>
                        <li>
                            <div className="style-guide-section-list">
                                Kupu i reira ko te whenua{" "}
                                <em>ki te Kai Tuhi </em> facilisis at iwi ki a
                                ratou. He nui rapea nga korero mo nga rori, mo
                                nga.
                            </div>
                        </li>
                        <li>
                            <div className="style-guide-section-list">
                                Ko te utu mo te Waka, Maori i te tau ka te 10s.,
                                he mea utu ki mua. Ka tukuna atu i te meera.
                            </div>
                        </li>
                        <li>
                            <div className="style-guide-section-list">
                                Me ka tukua mai e ia aua moni ki te Kai Tuhi ki
                                Poneke nei. Ta ratou kupu i reira ko te whenua
                                kia whakawakia kia{" "}
                                <a href="#">kia watea ratou i nga raruraru</a>{" "}
                                whakawakia Kua tonoa te Paremete e te Kawana kia
                                hui mai i te 2 o nga haora o te awatea o te
                                Taitei.
                            </div>
                        </li>
                    </div>
                </ul>
                <h4>Ordered</h4>
                <ol className="style-guide-section-list">
                    <li>
                        Kia whakawakia kia{" "}
                        <strong>E ngari me tino mohio ano te Muera</strong> ki
                        te tangata kei to mawehe atu.
                    </li>
                    <li>
                        Kei to mawehe atu <em>nga tikanga pera</em> ki te
                        tangata Ka tukuna atu i te meera ki te tangata e hiahia
                        ana hiahia.
                    </li>
                    <li>
                        Iwi ki a ratou. He nui rapea nga korero mo nga rori ki
                        te tangata e hiahia ana.
                    </li>
                    <li>
                        Iwi ki a ratou. He nui rapea nga korero mo nga rori, mo
                        nga ruritanga hoki, mo te aha atu; otira ko te tino kupu
                        tena, <a href="#">Ta ratou kupu i reira ko te whenua</a>{" "}
                        korero iwi ki a ratou. He nui rapea nga korero mo nga
                        rori ki te mahi i nga mahi o te motu.
                    </li>
                </ol>
            </StyleGuideSection>

            <StyleGuideSection
                title="Pātene"
                subtitle="Buttons"
                contain
                className="style-guide-buttons">
                <Button sizeStyle="large">
                    Button
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <Button>Button</Button>
                <Button sizeStyle="small">Button</Button>
                <br />
                <br />

                <Button variant="primary" sizeStyle="large">
                    Primary Button
                </Button>
                <Button variant="primary">
                    Primary Button{" "}
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <Button variant="primary">Primary Button</Button>
                <Button variant="primary" sizeStyle="small">
                    Primary Button
                </Button>
                <br />
                <br />

                <Button variant="secondary" sizeStyle="large">
                    Secondary Button
                </Button>
                <Button variant="secondary">Secondary Button</Button>
                <Button variant="secondary" sizeStyle="small">
                    Secondary Button{" "}
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <br />
                <br />

                <Button variant="tertiary" sizeStyle="large">
                    Tertiary Button{" "}
                    <Button.Icon className="fas fa-arrow-right" />
                </Button>
                <Button variant="tertiary">Tertiary Button</Button>
                <Button variant="tertiary" sizeStyle="small">
                    Tertiary Button
                </Button>

                <br />
                <br />
                <Button disabled>Disabled Button</Button>
                <Button disabled>Disabled Button</Button>
            </StyleGuideSection>

            <StyleGuideSection title="Tohutoro" subtitle="Blockquote" contain>
                <BlockQuote
                    text="
                    Matua Hautere te tipuna i hoea mai rā. 
                    I Hawaiki-nui, i Hawaiki-roa, Hawaiki-pāmamao e. 
                    Te ara whakaheke i tōna tipuna a Kupe. 
                    He tipua, he tangata Matua Hautere e."
                />
                <div className="content" />
            </StyleGuideSection>

            <StyleGuideSection title="Whakauta" subtitle="Loading" contain>
                <div className="style-guide-loaders">
                    <div>
                        <Loader size="large" />
                        <p>Large</p>
                    </div>
                    <div>
                        <Loader />
                        <p>Normal</p>
                    </div>
                    <div>
                        <Loader size="small" />
                        <p>Small</p>
                    </div>
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Whakahauhau" subtitle="Call To Action">
                <CallToActionBlock
                    data={{
                        __typename: "ComponentContentBlocksCallToAction",
                        id: "1",
                        callToActionTitle: "Register with Ngāti Kuia",
                        description: "Naumai haere mai",
                        action: {
                            label: "Join Us",
                            link: { slug: "/link" },
                            member_link: { slug: "/link" },
                        },
                        background: { url: "/images/intro-bg.jpg" },
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Kawepūrongo"
                subtitle="News Grid"
                contain={false}>
                <NewsGridBlock
                    data={{
                        __typename: "ComponentContentBlocksNewsGrid",
                        id: "1",
                        title: "Stay up to date",
                        pages: [
                            {
                                title: "Roadshows",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/roadshow.jpg",
                                },
                            },
                            {
                                title: "Kaumātua Christmas Lunch",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/xmas-lunch.jpg",
                                },
                            },
                            {
                                title: "Wānanga Pakohe",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/wānanga.jpg",
                                },
                            },
                            {
                                title: "Honey",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/honey.jpg",
                                },
                            },
                            {
                                title: "Ngāti Kuia Day",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/ngāti-kuia-day.jpg",
                                },
                            },
                            {
                                title: "Kānuka Tea",
                                slug: "/history",
                                cover_image: {
                                    url: "/images/kanuka-tea.jpg",
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tikiake" subtitle="Download">
                <DownloadBlock
                    data={{
                        __typename: "ComponentContentBlocksDownloadFiles",
                        id: "1",
                        heading: "Ngāti Kuia Annual Reports",
                        description:
                            "Ngāti Kuia annual report for the financial year 2021- 2022 entails the many achievements as well as financial transparency reporting. Iwi ki a ratou. He nui rapea nga korero mo nga rori, mo nga ruritanga hoki, mo te aha atu; otira ko te tino kupu tena.",
                        files: [
                            {
                                id: "1",
                                image: { url: "/images/intro-bg.jpg" },
                                name: "Annual Report 2020",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 2680900.21,
                                },
                            },
                            {
                                id: "2",
                                image: { url: "/images/intro-bg.jpg" },
                                file: {
                                    id: "1",
                                    name: "Chair Report 2019 - 2020",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 13809.66,
                                },
                            },
                            {
                                id: "3",
                                image: { url: "/images/intro-bg.jpg" },
                                name: "CEO Report 2020 - 2021",
                                file: {
                                    id: "1",
                                    name: "Chorus Fibre Consent Form",
                                    url:
                                        "http://www.ngatikuia.iwi.nz/assets/Uploads/Back-to-School-Grant-2020.pdf",
                                    size: 546.08,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Tiriata" subtitle="YouTube">
                <YoutubePlayerContentBlock
                    data={{
                        __typename: "ComponentContentBlocksYoutubePlayer",
                        id: "1",
                        title: "Video Title",
                        description:
                            "Videos to keep people on your website longer and engage them with your content. People work with people, and video helps people get to know, like and trust you. Videos keep your audience interested, no matter your product or service. Videos are fast and convenient.",
                        youtube_url: "https://youtu.be/SEkK7M9vge8",
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Rārangi Tiro" subtitle="Profile Grid">
                <section>
                    <div className="profile-grid-wrapper || constrain-width">
                        <h3>Tāngata</h3>
                        <div>
                            <p>
                                Throw in a mix of creative, editorial,
                                managerial and technical skills these are
                                without a doubt the plurality of skills of our
                                team that are Ngāti Kuia strength. Where is the
                                happiness officer and the smoothie bar? Not
                                here, here we prefer to rely on a strategy and a
                                thoughtful organization that promote the
                                development of our tāngata.
                            </p>
                        </div>

                        <div className="profile-grid-items">
                            <a
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/rebecca-mason.jpg")',
                                }}
                                href="#rebecca">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Rebecca Mason
                                    </h4>
                                </div>
                            </a>

                            <a
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/huataki-whareaitu.jpg")',
                                }}
                                href="#huataki">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Huataki Whareatiu
                                    </h4>
                                </div>
                            </a>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/kim-hippolite.jpg")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Kim Hippolite
                                    </h4>
                                </div>
                            </Link>
                        </div>

                        <div className="profile-grid-items">
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/waihaere-mason.jpg")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Waihaere Mason
                                    </h4>
                                </div>
                            </Link>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/william-wayne-hemi.jpg")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        William Wayne Hemi
                                    </h4>
                                </div>
                            </Link>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/elaine-wilson.jpg")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Elaine Wilson
                                    </h4>
                                </div>
                            </Link>
                        </div>

                        <div className="profile-grid-items">
                            <a
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/shanell-kelly.jpg")',
                                }}
                                href="#shanell">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Shanell Kelly
                                    </h4>
                                </div>
                            </a>
                            <Link
                                className="profile-grid-item has-background"
                                style={{
                                    backgroundImage:
                                        'url("/images/wānanga.jpg")',
                                }}
                                to="/">
                                <div>
                                    <h4 className="profile-grid-title has-background">
                                        Ariana Banks
                                    </h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
            </StyleGuideSection>

            <StyleGuideSection
                title="Pikitia Kōtaha"
                subtitle="Profile Pictures">
                <section>
                    <div className="profile-wrapper || constrain-width">
                        <h3>Ngāti Kuia Team</h3>
                        <div className="profile-content-title">
                            <h2 id="shanell" className="profile-subtitle">
                                Shanell Kelly
                            </h2>
                        </div>

                        <div className="profile-columns">
                            <div
                                className="profile-image right bottom"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/shanell-kelly.jpg")',
                                }}
                            />
                            <div className="profile-columns">
                                <div className="profile-columns-text">
                                    <h2 className="profile-subtitle">
                                        Software
                                    </h2>
                                    <p>
                                        Ekore e tika kia noho he Maori rawakore
                                        ki tenei whenua; ehara tenei i te mea e
                                        ora ai tatou e tika ai ranei ratou. E
                                        mea ana tatou kia noho te Maori i runga
                                        i te rangimarie penei me tatou, a e ki
                                        ana ahau mehemea tera tetahi iwi i
                                        whiwhi nui ki te whenua kua kite ratou
                                        kei to mawehe atu o ratou whenua i a
                                        ratou, e tika ana ano kia pouri ratou.
                                        Ko te utu mo te Waka, Maori i te tau ka
                                        te 10s., he mea utu ki mua. Ka tukuna
                                        atu i te meera ki te tangata e hiahia
                                        ana me ka tukua mai e ia aua moni ki te
                                        Kai Tuhi ki Poneke nei. Kua tonoa te
                                        Paremete e te Kawana kia hui mai i te 2
                                        o nga haora o te awatea o te Taitei, te
                                        15 o Hune, ki te mahi i nga mahi o te
                                        motu. Ta ratou kupu i reira ko te whenua
                                        kia whakawakia kia hokona, kia watea
                                        ratou i nga raruraru e homai ana e etahi
                                        iwi ki a ratou. He nui rapea nga korero
                                        mo nga rori, mo nga ruritanga hoki, mo
                                        te aha atu; otira ko te tino kupu tena,
                                        ko te whakawa, ko te hoko. Nga mihi
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="profile-wrapper || constrain-width">
                        <div className="profile-content-title-right">
                            <h2 id="rebecca" className="profile-subtitle">
                                Rebecca Mason
                            </h2>
                        </div>

                        <div className="profile-columns">
                            <div
                                className="profile-image left bottom"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/rebecca-mason.jpg")',
                                }}
                            />
                            <div className="profile-columns-right">
                                <div className="profile-columns-text">
                                    <h2 className="profile-subtitle">
                                        Software
                                    </h2>
                                    <p>
                                        Rebecca is a mokopuna of Martin Mason
                                        and Haromi Walker, and has whakapapa
                                        links to Meihana Kereopa, Hana Whiro,
                                        Ina Hemi, Jimmy Walker, Wi Te Mete and
                                        Tire White. She is a Trustee responsible
                                        for our Te Putea Pou along with Wayne
                                        Hemi, and is a member of our Grants
                                        Komiti. She is also Ngāti Kuia’s
                                        representative on the Nelson/Marlborough
                                        Iwi Health Board and is overseeing our
                                        Hauora initiative He Maunga Pakohe
                                        Rautaki Hauora. Rebecca has worked for
                                        major corporations and government
                                        departments in NZ and London. She has
                                        been responsible for country-wide
                                        strategic planning processes, has broad
                                        commercial and pricing experience and
                                        has brokered international
                                        collaborations. She is also a Chartered
                                        Accountant and member of the Institute
                                        of Directors. Rebecca lives in central
                                        Nelson, is married with two children and
                                        is learning Te Reo at Ngāti Kuia night
                                        classes
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="profile-wrapper || constrain-width">
                        <div className="profile-content-title">
                            <h2 id="huataki">Huataki Whareatiu</h2>
                        </div>

                        <div className="profile-columns">
                            <div
                                className="profile-image right bottom"
                                style={{
                                    backgroundImage:
                                        'url("/images/tāngata/huataki-whareaitu.jpg")',
                                }}
                            />
                            <div className="profile-columns">
                                <div className="profile-columns-text">
                                    <h2>Software</h2>
                                    <p>
                                        He uri whakaheke ahau nō Tūtepourangi
                                        rāua ko Hinearorangi Nā rāua ko Hinekawa
                                        ka noho ia i a Te Kotimana a Jock
                                        McGregor ka hikaia atu ko Teone
                                        Makerika, ka noho a Teone i a Pirihira
                                        Poutu nō Ngati Raukawa hei wahine
                                        tuatahi māna, ka hikaia atu ko Hokowhitu
                                        Makerika ka noho i a Titihuia Ngāpu ka
                                        hikaia atu ko Rangitaiki Makerika ka
                                        noho i a William Stretch ka hikaia atu
                                        ko taku māmā a Te Hanatia kātahi ko au
                                        ko Huataki. I am a kaitiaki of the Ngati
                                        Kuia Pou this role has responsibility to
                                        ensure our tikanga is present and
                                        observed at all levels of our operation
                                        Mai i te Kāhui maunga ki Tangaroa.
                                        Another role is to assist as kaitiaki
                                        for the Tangata Pou our responsibility
                                        is for you Te Iwi. I’m fortunate to be
                                        part of the “He Maunga Pakohe Rautaki
                                        Hauora” Health initiative. Currently I
                                        am employed as Cultural Competency and
                                        Tikanga Facilitator by ‘Te Pae Hauora o
                                        Ruahine o Tararua’ ‘MidCentral DHB’ in
                                        the Manawatū. I am part of Te Rōpū Pae
                                        Ora Māori Health Directorate we work out
                                        of Te Whare Rapuora based at the
                                        Palmerston North Hospital Campus. One of
                                        my roles is to ensure Cultural
                                        Competency is present in all services
                                        across the MCDHB catchment. I am also
                                        Cultural Competency and Tikanga advisor
                                        for the Palmerston North Office of
                                        Oranga Tamariki. Finally I’m the hoa
                                        Tāne of Mokikiwa, a Pāpā of four sons
                                        and a daughter, a koroua of nine
                                        mokopuna “me taku eke ki te angitū
                                        hoki”, an uncle of many, cousin,
                                        brother, he pononga mō āku iwi.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </StyleGuideSection>

            <StyleGuideSection title="Hononga" subtitle="Links">
                <LinksBlock
                    data={{
                        __typename: "ComponentContentBlocksLinks",
                        id: "123",
                        title: "Helpful Links",
                        description:
                            "Info and resources specifically for whanau. He nui rapea nga korero mo nga rori, mo nga ruritanga hoki, Latest from Ngāti Kuia. Information on Health. Iwi ki a ratou. Looking after mental health and wellbeing during covid. mo te aha atu; otira ko te tino kupu",
                        links: [
                            {
                                title: "Ngāti kuia Honey",
                                url:
                                    "https://www.aceaotearoa.org.nz/news-and-resources/news/ng%C4%81ti-kuia-building-wh%C4%81nau-skills",
                            },
                            {
                                title: "Covid Advice for Māori",
                                url:
                                    "https://www.aceaotearoa.org.nz/news-and-resources/news/ng%C4%81ti-kuia-building-wh%C4%81nau-skills",
                            },
                            {
                                title: "Healthline",
                                url:
                                    "https://www.aceaotearoa.org.nz/news-and-resources/news/ng%C4%81ti-kuia-building-wh%C4%81nau-skills",
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection
                title="Kete Whakaahua"
                subtitle="Gallery"
                contain={false}>
                <GalleryBlock
                    data={{
                        __typename: "ComponentContentBlocksImageGallery",
                        id: "1",
                        heading: "Kotahi",
                        galleryId: "123456",
                        description:
                            "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. I te hokinga mai o ona tuakana ki tatahi, ka kii atu a Maui, “ka taea e au te haramai i to koutou na taha ki te hii ika?” Engari, ko te whakautu o ona tuakana ki a ia ano, “Kao, he rangatahi noa iho koe. Kaore he wahi mau kei te waka nei, na reira me noho tau ki tatahi ke”.",
                        images: [
                            {
                                heading: "Rua",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika. ratou. He nui rapea nga korero mo nga roriratou. He nui rapea nga korero mo nga roriratou. He nui rapea nga korero mo nga rori",
                                image: {
                                    alternativeText: "",
                                    url: "/images/intro-bg.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Wha",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/weave.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Tahi",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou Maui kia haere ngatahi tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/paddock.png",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Toru",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/waka.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Rima",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/intro-bg.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                            {
                                heading: "Ono",
                                description:
                                    "Ko te moemoea a Maui kia haere ngatahi ai ratou ko ona tuakana ki te hii ika.",
                                image: {
                                    alternativeText: "",
                                    url: "/images/weave-white.jpg",
                                    height: 800,
                                    width: 800,
                                },
                            },
                        ],
                    }}
                />
            </StyleGuideSection>

            <StyleGuideSection title="Puka" subtitle="Forms" contain>
                <Form className="constrain-width">
                    <div className="form-row">
                        <Field
                            label="Example Tahi"
                            type="text"
                            name="example-tahi"
                        />
                        <Field
                            label="Example Rua"
                            type="text"
                            name="example-rua"
                        />
                        <Field
                            label="Example Toru"
                            type="text"
                            name="example-toru"
                        />
                    </div>
                    <div className="form-row">
                        <Field
                            label="Example Wha"
                            type="text"
                            name="example-wha"
                        />
                    </div>
                    <div className="form-row">
                        <Field type="text" name="example-wha" />
                    </div>
                </Form>
            </StyleGuideSection>

            <StyleGuideSection title="Whakataka" subtitle="Dropdown" contain>
                <Form className="constrain-width">
                    <div className="form-row">
                        <Dropdown
                            label="Title"
                            options={["Mr", "Mrs", "Miss", "Other"]}
                        />
                        <Dropdown
                            label="Education"
                            options={[
                                "Study",
                                "Apprenticeship",
                                "Tertiary",
                                "Degree",
                                "Masters",
                                "Trade",
                                "Other",
                            ]}
                        />
                    </div>
                </Form>
            </StyleGuideSection>

            <StyleGuideSection
                title="Pātene Irirangi"
                subtitle="Radio Buttons"
                contain>
                <div className="constrain-width">
                    <RadioColumn label="Gender" text="Wahine" />
                    <RadioColumn text="Tane" />
                    <RadioColumn text="Ira Weherua-kore" />
                    <div className="radio-row">
                        <Radio text="Mr" />
                        <Radio text="Mrs" />
                        <Radio text="Miss" />
                    </div>
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Pōuakataki" subtitle="Checkboxes" contain>
                <div className="constrain-width">
                    <p>
                        {" "}
                        I agree that I have read and accepted the terms and
                        conditions{" "}
                    </p>
                    <Checkbox text="Āe" />
                    <Checkbox text="Kaō" />
                </div>
            </StyleGuideSection>

            <StyleGuideSection title="Rā Whānau" subtitle="Birthdate" contain>
                <Form className="constrain-width">
                    <div className="form-row">
                        <DatePicker
                            defaultDate={new Date("01/12/2022")}
                            name="birthdate"
                        />
                    </div>
                </Form>
            </StyleGuideSection>

            <StyleGuideSection
                title="Rapu Wāhi Noho"
                subtitle="Address Finder"
                contain>
                <Form className="constrain-width">
                    <AddressFinder onChange={() => {}} />
                </Form>
            </StyleGuideSection>

            <StyleGuideSection
                title="Papa Waitohu"
                subtitle="Signature Pad"
                contain>
                <Form className="constrain-width">
                    <SignatureCanvas text="Please sign on the signature pad" />
                </Form>
            </StyleGuideSection>

            <StyleGuideSection title="Dynamic" subtitle="Field" contain>
                <Form className="constrain-width">
                    <Whakapapa onChange={() => {}} />
                </Form>
            </StyleGuideSection>
        </div>
    );
};
